import React from "react"
import { graphql } from "gatsby"
// import { withPreview } from 'gatsby-source-prismic'
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SliceZone from "../components/SliceZone"

const isBrowser = typeof window !== "undefined"

const IndexPage = ({ location, data }) => {
  if (isBrowser) {
    
    if (!data) return null

    const url = location.href ? location.href : '';
    const { type } = data.allPrismicHomepage.edges[0].node
    const seo = data.allPrismicHomepage.edges[0].node.data.body1[0].primary
    const document = data.allPrismicHomepage.edges[0].node.data

    return (
      <div>
        <Layout pageType={type} uid="index">
          <Seo title={document.title.text} seo={seo} url={url} />
          <SliceZone sliceZone={document.body} />
        </Layout>
      </div>
    )
  }

  return <div> </div>
}

export const query = graphql`
  {
    allPrismicHomepage {
      edges {
        node {
          type
          data {
            title {
              text
            }
            body {
              ... on PrismicHomepageDataBodyHero {
                slice_type
                primary {
                  hero_title {
                    text
                  }
                  hero_copy {
                    text
                  }
                  hero_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
              ... on PrismicHomepageDataBodyCardPromo {
                slice_type
                primary {
                  card_icon {
                    url
                  }
                  share_price_card_icon {
                    url
                  }
                  card_title {
                    text
                  }
                  share_price_card_title {
                    text
                  }
                  share_price_card_footer {
                    html
                  }
                  link_to_promo_document_1 {
                    uid
                  }
                }
              }
              ... on PrismicHomepageDataBodyBanner {
                slice_type
                primary {
                  banner_position
                  banner_copy {
                    text
                  }
                  banner_button_copy {
                    text
                  }
                  banner_button_link {
                    uid
                    url
                    target
                  }
                  button_icon
                  banner_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
              ... on PrismicHomepageDataBodyStats {
                slice_type
                primary {
                  section_title {
                    text
                  }
                  free_entry_copy {
                    text
                  }
                  button_link {
                    uid
                    url
                    target
                  }
                  button_copy
                }
                items {
                  stat_copy {
                    text
                  }
                  stat_title {
                    text
                  }
                }
              }
              ... on PrismicHomepageDataBodyNews {
                slice_type
                primary {
                  section_title {
                    text
                  }
                }
              }
              ... on PrismicHomepageDataBodyQuickLinks {
                slice_type
                items {
                  link_title
                  link {
                    url
                    uid
                  }
                  link_icon {
                    alt
                    url
                  }
                }
              }
            }
            body1 {
              ... on PrismicHomepageDataBody1SeoPages {
                primary {
                  description
                  og_title
                  og_description
                  og_image {
                    url
                  }
                  og_image_alt
                  twitter_image {
                    url
                  }
                  twitter_image_alt
                }
              }
            }
          }
        }
      }
    }
  }
`

// export default withPreview(IndexPage)
export default IndexPage
